@mixin border {
  border: px2rem(1px) solid $border-color;
}

@mixin border-top {
  border-top: px2rem(1px) solid $border-color;
}

@mixin border-bottom {
  border-bottom: px2rem(1px) solid $border-color;
}

@mixin border-left {
  border-left: px2rem(1px) solid $border-color;
}

@mixin border-right {
  border-right: px2rem(1px) solid $border-color;
}
