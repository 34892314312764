$white: var(--white);
$black: var(--black);
$green: var(--green);
$primary: var(--primary);
$primary-bg: var(--primary-bg);
$secondary: var(--secondary);
$secondary-bg: var(--secondary-bg);
$wave-white: var(--wave-white);
$wave-black: var(--wave-black);
$grey: var(--grey);
$red: var(--red);
$subtitle: var(--subtitle);
$primary-bg-rgb: var(--primary-bg-rgb);
$primary-rgb: var(--primary-rgb);
