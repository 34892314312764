$xxs: 0;
$xs: 360px;
$xs2: 480px;
$xs3: 500px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@mixin xs {
  @media (min-width: $xs) {
    @content;
  }
}

@mixin xs-down {
  @media (max-width: #{$xs - 0.01px}) {
    @content;
  }
}

@mixin xs2 {
  @media (min-width: $xs2) {
    @content;
  }
}

@mixin xs2-down {
  @media (max-width: #{$xs2 - 0.01px}) {
    @content;
  }
}

@mixin xs3 {
  @media (min-width: $xs3) {
    @content;
  }
}

@mixin xs3-down {
  @media (max-width: #{$xs3 - 0.01px}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: #{$sm - 0.01px}) {
    @content;
  }
}

@mixin md {
  @media (min-width: $md) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: #{$md - 0.01px}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: #{$lg - 0.01px}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin xl-down {
  @media (max-width: #{$xl - 0.01px}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: $xxl) {
    @content;
  }
}

@mixin xxl-down {
  @media (max-width: #{$xxl - 0.01px}) {
    @content;
  }
}

@mixin breakpoint($min, $max) {
  @media (min-width: $min) and (max-width: #{$max - 0.1px}) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
