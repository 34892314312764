.top-panel {
  &.xpack-o-root {
    border: none;
    @include border-bottom;

    @include lg {
      --navbar-max-width: px2rem(240px);
      width: 100%;
      flex-direction: row;
      height: min-content;
      position: relative;
    }
  }

  .xpack-o-root {
    &__nav-items {
      height: calc(100vh - px2rem(35px));

      @include lg {
        position: fixed;
        top: px2rem(35px);
        display: block;
        padding-bottom: px2rem(8px);
        transform: translateX(-100%);
      }

      &.show {
        display: block;
        box-shadow: 0 px2rem(2px) px2rem(4px) #a6a6a6;
        transform: translateX(0);
        transition: transform 0.2s ease-out;
      }
    }

    &__title {
      display: flex;
    }

    &__button-close,
    &__button-setting {
      @include lg {
        display: flex;
      }
    }

    &__frame-control {
      @include lg {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
      }
    }

    &__active-item-options {
      @include lg {
        display: block;
        position: fixed;
        transform: translateX(100%);
        top: px2rem(31px);
      }

      &.show {
        display: block;
        box-shadow: 0 px2rem(2px) px2rem(4px) #a6a6a6;
        transform: translateX(0);
        transition: transform 0.2s ease-out;
      }
    }

    &__actual-width {
      @include lg {
        margin-top: px2rem(4px);
      }
    }

    &__controls {
      @include lg {
        display: block;
      }
    }
  }
}
